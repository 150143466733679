import { getPage, WagtailApiResponseError } from '@api/wagtail';
import LazyViews from '@views/LazyViews';
import type { PageData } from '@/types';
import { getSession } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import LoadingSpinner from '@components/LoadingSpinner';

const isProd = process.env.NODE_ENV === 'production';

async function getData(path: string): Promise<PageData | any> {
  const session = await getSession();
  let authheader = { Authorization: '' };
  if (session?.token) {
    authheader = { Authorization: `Token ${session?.token}` };
  }

  try {
    const pageData = await getPage(path, undefined, { headers: authheader }).then(
      response => response.json,
    );
    return pageData;
  } catch (err) {
    if (!(err instanceof WagtailApiResponseError)) {
      throw err;
    }
    if (!isProd && err.response.status >= 500) {
      const html = await err.response.text();
      return {
        props: {
          componentName: 'PureHtmlPage',
          componentProps: { html },
        },
      };
    }

    if (err.response.status >= 500) {
      throw err;
    }

    return { notFound: true };
  }
}

function CatchAllPage() {
  const router = useRouter();

  let path = router.asPath;
  if (path.includes('?')) {
    path = path.substring(0, path.indexOf('?'));
  }

  const { isError, isLoading, data }: { isError: any; isLoading: any; data: PageData | undefined } =
    useQuery([path], () => getData(path));
  if (isLoading) return <LoadingSpinner />;
  if (isError) return <LoadingSpinner />;

  if (data) {
    if (data.meta?.type !== undefined) {
      const Component = LazyViews[data.meta.type];
      if (!Component) {
        return <h1>Component {data.meta.type} not found</h1>;
      }
      // @ts-ignore
      return <Component {...data} />;
    }
    if (data.meta?.type === undefined && router.pathname !== '/') {
      router.push('/');
    }
  }
}

export default CatchAllPage;
