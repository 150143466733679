import React from 'react';

type Props = {};

const LoadingSpinner = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div className='loading-spinner' ref={ref}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
});

LoadingSpinner.displayName = 'LoadingSpinner';

export default LoadingSpinner;
